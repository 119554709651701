<template>
  <base-material-modal
    v-model="openReservation"
    icon="mdi-animation-outline"
    title="Añadir reserva"
    width="700"
    @input="close"
  >
    <template v-slot:content>
      <validation-observer ref="reserva_form" v-slot="{invalid}">
        <v-row>
          <v-col cols="6">
            <validation-provider name="Nombre" vid="nombre" rules="required" v-slot="{errors}">
              <v-text-field
                v-model="reservation.name"
                label="Nombre"
                prepend-icon="mdi-playlist-edit"
              ></v-text-field>
            <div class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider name="Apellido" vid="apellido" rules="required" v-slot="{errors}">
              <v-text-field
                v-model="reservation.last_name"
                label="Apellido"
                prepend-icon="mdi-playlist-edit"
              ></v-text-field>
              <div class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider name="Correo" vid="correo" rules="required|email" v-slot="{errors}">
              <v-text-field
                v-model="reservation.email"
                label="Correo"
                prepend-icon="mdi-playlist-edit"
              ></v-text-field>
              <div class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider name="Comensales" vid="comensales" rules="required" v-slot="{ errors }">
              <v-text-field
                :class="{'error--text': errors[0]}"
                v-model="reservation.diners"
                :rules="[rules.required,rules.cantMax]"
                label="Numero de comensales"
                prepend-icon="mdi-playlist-edit"
              ></v-text-field>
              <div class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider name="Telefono" vid="telefono" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="reservation.phone"
                label="Teléfono"
                prepend-icon="mdi-playlist-edit"
              ></v-text-field>
              <div class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>
            </validation-provider>
          </v-col>
        </v-row>
        <v-btn color="primary" :disabled="invalid"  @click="validarReserva">Crear reserva</v-btn>
      </validation-observer>


    </template>
  </base-material-modal>
</template>
<script>
export default {
  name: "CreateReservation",
  props: ["openReservation", "restaurant_id", "eventForReserva","url"],
  data() {
    return {
      reservation: {
        name: "",
        email: "",
        last_name: "",
        start_time: "",
        end_time: "",
        diners: "",
        date: "",
        phone: "",
        restaurant_id: null
      },
      rules: {
          required: value => !!value || 'Campo requerido.',
          cantMax: value => value <= this.eventForReserva.max_person || "Cantidad de comensales superado"
        }
    };
  },
  methods: {
    validarReserva(){
        this.$refs.reserva_form.validate().then(result =>{
          if(result){
            this.checkDisponibilidad();

          }
        })
      },
      async checkDisponibilidad(){

        let event = this.eventForReserva;
        let url = '';
        if (this.url === null) {
          url = "api/schedule/"+event.id_horario+"/check?start_time="+event.start_time+"&end_time="+event.end_time+"&restaurant_id="+this.restaurant_id;
        }else{
          url = "api/restaurant/"+this.url+"/reservations/"+event.id_horario+"/check?start_time="+event.start_time+"&end_time="+event.end_time+"&restaurant_id="+this.restaurant_id;
        }
        this.$api.get(url).then(res=>{
          let status = res.data.status;
          if (status) {
            this.savereserva();
          }else{
            this.$root.$snakbar({
            text: res.data.message,
            type: "danger",
            active: true
          });
          this.$emit('updateAll');
          this.$refs.reserva_form.reset();
          this.close(false);
          }
        })

      },
      async savereserva() {

      let url
      if (this.url === null) {
        url = "api/booking";
      }else{
        url = `api/restaurant/${this.url}/booking`
      }
      let data = this.reservation;
      data.start_time = this.eventForReserva.start_time;
      data.end_time = this.eventForReserva.end_time;
      data.date = this.eventForReserva.date.replaceAll("-", "/");
      data.restaurant_id = this.restaurant_id;
      await this.$api
        .post(url, data)
        .then(res => {

          this.$root.$snakbar({
            text: res.data.message,
            type: "success",
            active: true
          });
          this.$emit('updateAll');
          this.$refs.reserva_form.reset();
          this.close(false);
        })
        .catch(e => {
          this.close(false);
          this.$root.$snakbar({
            text: e.message,
            type: "success",
            active: true
          });
        });
    },
    close: function(val) {
      this.defaultReservation();
      this.$emit("closeModalReserva", val);
    },
    defaultReservation(){
      return this.reservation = {
        name: "",
        email: "",
        last_name: "",
        start_time: "",
        end_time: "",
        diners: "",
        date: "",
        phone: "",
        restaurant_id: null
      };

    }
  },
  watch:{
    openReservation:function(){
      this.$refs.reserva_form.reset();
    }
  }
};
</script>
