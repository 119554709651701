<template>
  <v-container>
    <v-row class="box-letter" v-if="load_reservas">
      <v-col cols="6" sm="4" md="4" lg="4" class="pt-1" v-for="n in 4" :key="n">
        <v-sheet class="">
          <v-responsive class="mx-auto">
            <v-skeleton-loader
              ref="skeleton"
              type="card"
              height="130"
              class="mx-auto"
            ></v-skeleton-loader>
          </v-responsive>
        </v-sheet>
      </v-col>
    </v-row>
    <!-- ITEMS CARTA -->

    <!-- <calendar v-else :horarios="horarios" :reservations="reservations" :ready="ready"></calendar> -->
    <v-row justify="space-between">
      <v-col cols="6">
        <div class="subheading">Seleccione una fecha.</div>
        <v-date-picker
          locale="es-ES"
          v-model="fechaActual"
          :event-color="eventColor"
          :events="arrayEvents"
          @click:date="reservaShow"

        ></v-date-picker>
      </v-col>
      <v-col cols="6">
        <v-row cols="6">
          <v-col cols="12">
            <div class="subheading">Horarios disponibles.</div>
          </v-col>
          <v-col cols="12" v-if="horariosDisponibles.length <= 0">
            No hay horarios disponibles
          </v-col>
          <v-col v-else  cols="4" v-for="(horario,index) in horariosDisponibles" :key="index" >
            <v-btn @click="doReserva(horario)" rounded color="blue" dark>{{horario.start_booking}} a {{horario.end_booking}}</v-btn>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
    <crearReservation
      @closeModalReserva="updateModalReservation"
      @updateAll="init"
      :openReservation="openReservation"
      :restaurant_id="restaurant_id"
      :eventForReserva="eventForReserva"
      :url="url"
    />
  </v-container>
</template>
<script>
import crearReservation from "../../components/CrearReservation";
export default {
  name: "Booking",
  components: { crearReservation},

  data() {
    return {
      load_reservas: false,
      reservations: [],
      horarios: [],
      ready: false,
      url: this.$route.params.landing ? this.$route.params.landing : "",
      value: "",
      events: [],
      search: "",
      restaurant_id: null,
      url_edit: "",
      modal_horario: false,
      saving_horario: false,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      reservationDay: [],
      openReservation: false,
      eventForReserva: {},
      redirect: "",
      arrayEvents: [],
      fechaActual: new Date().toISOString().substr(0, 10),
      horariosDisponibles:[]
    };
  },
  mounted () {
  },
  created() {
    this.init();
  },
  updated() {
    this.restaurant_id = this.$store.state.landing.restaurant_id;
  },
  methods: {
    functionEvents (date) {
        const [ , , day] = date.split('-')
        /* console.log(year,month,day) */

        if ([12, 17, 28].includes(parseInt(day, 10))) return true
        if ([1, 19, 22].includes(parseInt(day, 10))) return ['red', '#00f']
        return false
    },
    init() {
      this.restaurant_id = this.$store.state.landing.restaurant_id;
      this.getHorarios();
      this.getReservations();
    },
    async getReservations() {
      this.$api
        .get(`api/restaurant/${this.url}/reservations`)
        .then(res => {
          this.reservations = res.data.reservation;
        })
        .catch(error => {

          console.log(error);
        });
    },
    async getHorarios() {
      this.load_letters = true;
      this.$api.get(`api/restaurant/${this.url}/schedule`).then(res => {
        var data = res.data.data;
        this.horarios = data;
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            /* const element = data[key]; */
            this.arrayEvents.push(key);
          }
        }
        this.ready = true;
      });
    },
    reservationsDisponible(event) {
      let d = event.input.start;
      let maxbooking = parseInt(event.input.maxBooking);
      let fecha =
        d.getFullYear() +
        "-" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + d.getDate()).slice(-2);
      let hora_inicio = d.getHours() + ":" + ("0" + d.getMinutes()).slice(-2);

      this.reservations.forEach(element => {
        if (element.date == fecha && element.start_time == hora_inicio) {
          --maxbooking;
        }
      });
      return maxbooking;
    },
    updateModalReservation: function(val) {
      this.openReservation = val;
    },
    eventColor(){
      return 'blue';

    },
    reservaShow(event){

    },
    doReserva(horario){
      let h = {
        start_time:horario.start_booking,
        end_time:horario.end_booking,
        restaurant_id: this.restaurant_id,
        date:horario.date,
        max_person:horario.max_person,
        id_horario:horario.id_horario
      }
      this.eventForReserva = h;
      this.openReservation = true;
    }

  },
  watch:{
    fechaActual:function(){
      let fecha = this.fechaActual;
      if (fecha) {
        this.$api.get(`api/restaurant/${this.url}/schedule/${fecha}`).then(res => {

          var data = res.data.horario;
          this.horariosDisponibles = data;
      });
      }

    }
  }
};
</script>
